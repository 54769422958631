import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Seo from './../components/Layout/Seo'
import { Socials } from './../components/Shared'
import Title from './../components/Title'
import LinkedImage from './../components/LinkedImage'

const Wrapper = styled.section`
    width: 100%;

    padding-top: 8rem;

    ${props => props.theme.above.desktop`
        padding-top: 0;
    `}
`

const Contact = styled.div`
    width: 100%;

    margin: 0 auto 6rem auto;

    ${props => props.theme.above.desktop`
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        max-width: 100rem;

        height: 100vh;
        min-height: 100rem;
        max-height: 150rem;

        margin: 0 auto;
    `}
`

const StyledTitle = styled(Title)`
    padding-right: 25vw;

    ${props => props.theme.above.desktop`
        margin-bottom: 3rem;
        padding: 0;
    `}
`

const SubtitleStyles = css`
    margin-bottom: 1.5rem;

    font-family: ${props => props.theme.fontFamily.circularMedium};
    font-size: 1.6rem;
    line-height: 2rem;
`

const Content = styled.div`
    padding: 0 3rem;

    ${props => props.theme.above.desktop`
        padding: 0;
    `}
`

const Description = styled(motion.div)`
    h4 {
        ${SubtitleStyles}
    }

    p {
        margin-bottom: 3rem;
    }

    a {
        display: inline-block;

        position: relative;

        font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
        letter-spacing: 0.12rem;

        ${props => props.theme.underline(props.theme.colors.white, '0.1rem')}

        &:hover {
            color: ${props => props.theme.colors.white};
        }
    }

    ${props => props.theme.above.desktop`
        display: inline-block;
        vertical-align: top;

        width: 80%;

        padding-right: 15rem;

        a {
            font-size: 1.6rem;
        }
    `}
`

const Social = styled(motion.div)`
    width: 100%;
    
    ${props => props.theme.above.desktop`
        display: inline-block;
        vertical-align: top;

        width: 20%;
    `}
`

const SocialTitle = styled.h4`
    ${SubtitleStyles}
`

const StyledSocials = styled(Socials)`
    color: ${props => props.theme.colors.darkText};

    .icon {
        path {
            fill: ${props => props.theme.colors.darkText};
        }
    }

    .item {
        &:hover {
            color: ${props => props.theme.colors.white};

            path {
                fill: ${props => props.theme.colors.white};
            }
        }
    }
`

const StyledLinkedImage = styled(LinkedImage)`
    width: 100%;
    height: 100%;

    max-height: 50rem;

    overflow: hidden;

    ${props => props.theme.above.desktop`
        max-height: 80rem;
    `}
`

const ContactPage = ({
    pageContext: {
        lang,
        langSlug
    },
    data: {
        contact: {
            data: {
                content_title,
                content_subtitle, 
                content,
                social,
                social_links,
                gallery,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image
            }
        }
    }
}) => {

    const [ref, inView, entry] = useInView({
        triggerOnce: true
    })

    const animation = inView ? 'after' : 'before'

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Wrapper ref={ref}>
                <Contact>
                    <StyledTitle
                        title={content_title.text}
                        subtitle={content_subtitle}
                        alignment='left'
                    />
                    <Content>
                        <Description 
                            dangerouslySetInnerHTML={{ __html: content.html }} 
                            animate={animation}
                            variants={{
                                before: {
                                    opacity: 0,
                                    y: 100
                                },
                                after: {
                                    opacity: 1,
                                    y: 0,
                                }
                            }}
                            transition={{
                                duration: 1,
                                damping: 100,
                                stiffness: 100,
                                ease: 'circOut'
                            }}
                        />
                        <Social
                            animate={animation}
                            initial={{
                                opacity: 0
                            }}
                            variants={{
                                before: {
                                    opacity: 0,
                                    y: 100
                                },
                                after: {
                                    opacity: 1,
                                    y: 0,
                                }
                            }}
                            transition={{
                                duration: 1,
                                delay: 0.1,
                                damping: 100,
                                stiffness: 100,
                                ease: 'circOut'
                            }}
                        >
                            <SocialTitle>{social[0].title.text}</SocialTitle>
                            <StyledSocials 
                                type='list' 
                                data={social_links} 
                            />
                        </Social>
                    </Content>
                </Contact>
                {gallery[0].items.length > 1 ?
                    <AliceCarousel
                        buttonsDisabled={true}
                        dotsDisabled={true}
                        duration={1000}
                        autoPlayInterval={8000}
                        autoPlay={true}
                        fadeOutAnimation={true}
                        disableAutoPlayOnAction={true}
                        responsive={{
                            0: {
                                items: 1
                            }
                        }}
                    >
                        {gallery[0].items.map(({ image, product_link }, index) => {
                            return (
                                <StyledLinkedImage
                                    key={index}
                                    lang={langSlug}
                                    product={product_link && product_link.document}
                                    image={image}
                                />
                            )
                        })}
                    </AliceCarousel> 
                : 
                    <StyledLinkedImage
                        lang={langSlug}
                        product={gallery[0].items[0].product_link.document}
                        image={gallery[0].items[0].image}
                    />
                }
            </Wrapper>
        </>
    )
}

export const QUERY_CONTACTPAGE = graphql`
    query ContactPage($id: String!) {
        contact: prismicContactPage(id: { eq: $id }) {
            id
            lang
            ...ContactPageQuery
        }
    }
`

export default ContactPage